import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-not-found',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  notFoundBackgroundImage =
    // tslint:disable-next-line:max-line-length
    'https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fnot-found-images%2Fnot-found-background.png?alt=media&token=8d55d0ac-fa73-4966-9e71-49a320438a88';
}
