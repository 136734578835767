import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// services
import { SeoService } from '../../../shared/services/seo/seo.service';

@Component({
  selector: 'app-services',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  serviceBackgroundImage: string;
  whyKuestBackgroundImage =
    // tslint:disable-next-line:max-line-length
    'https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fservices-images%2Fkuest-image.jpg?alt=media&token=473ee2ed-6120-4714-94e8-5d5fbaff8e9b';

  // seo
  seoTitle =
    'Kuest: Software Development Company | Services and custom development';
  seoDescription = '';
  // seoImage =
  seoSlug = 'services';

  constructor(private seo: SeoService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(this.seoTitle);

    this.serviceBackgroundImage = `url('../../../../assets/images/landing/home/presentation-section/home-bg2.jpg')`;

    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });
  }
}
