import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

// interfaces
import { StartupItem } from '../../../../common/models/interfaces/startup-item';

@Component({
  selector: 'app-start-ups-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './start-ups-view.component.html',
  styleUrls: ['./start-ups-view.component.scss']
})
export class StartUpsViewComponent implements OnInit {
  startupItems: StartupItem[];
  selectedStartupItem: StartupItem;

  startupsBackgroundImage: string;

  ngOnInit() {
    this.fillStartupItems();
    this.selectStartupItem(1);

    this.startupsBackgroundImage = `url('../../../../assets/images/landing/home/presentation-section/home-bg1.jpg')`;
  }

  selectStartupItem(id: number) {
    this.selectedStartupItem = this.startupItems[id - 1];
  }

  fillStartupItems() {
    this.startupItems = [
      {
        id: 1,
        title: 'Product Development',
        description:
          'We have the best product developers in-house who turn good idea into a great product.'
      },
      {
        id: 2,
        title: 'Business Development',
        description:
          'Our business developers bring startup & corporate biz dev expertise to your business.'
      },
      {
        id: 3,
        title: 'Marketing & Growth',
        description:
          'Our growth hackers are here to hack the growth and scale up your product.'
      },
      {
        id: 4,
        title: 'Legal & Operations',
        description:
          'We will do the legal work and look after Operations so you focus on what matters'
      }
    ];
  }
}
