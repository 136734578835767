import {
  trigger,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations';

export const headerAnimations = [
  trigger('slide', [
    transition(':enter', [
      animate(
        500,
        keyframes([
          style({ top: '-300px', opacity: 0 }),
          style({ top: 0, opacity: 1 })
        ])
      )
    ]),
    transition(':leave', [
      animate(
        500,
        keyframes([
          style({ top: 0, opacity: 1 }),
          style({ top: '-300px', opacity: 0 })
        ])
      )
    ])
  ])
];
