import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// interfaces
import { News } from '../../../../common/models/interfaces/news';

@Component({
  selector: 'app-news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.scss']
})
export class NewsViewComponent {
  @Input() news: News[];

  constructor(private router: Router) {}

  navigateToNews(newsId: string) {
    this.router.navigate([`/news/${newsId}`]);
  }
}
