import { Component, OnInit } from '@angular/core';

// services
import { LandingService } from '../../../shared/services/landing/landing.service';
import { SnackBarService } from '../../../../common/services/snack-bar/snack-bar.service';
import { SeoService } from '../../../shared/services/seo/seo.service';

// interfaces
import { StartupApplication } from '../../../../common/models/interfaces/startup-application';

@Component({
  selector: 'app-start-ups-apply',
  templateUrl: './start-ups-apply.component.html',
  styleUrls: ['./start-ups-apply.component.scss']
})
export class StartUpsApplyComponent implements OnInit {
  // snackbar
  snackMessage = 'Form submittion saved successfully. Redirecting now...';
  snackNavigation = '/';
  snackTime = 3000;

  // seo
  seoTitle = 'Kuest: Software Development Company | Apply as a Startup';
  seoDescription = '';
  // seoImage =
  seoSlug = 'startups/apply';

  constructor(
    private seo: SeoService,
    private landingService: LandingService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });
  }

  async handleFormApplication(formValue: StartupApplication) {
    try {
      await this.landingService.saveStartup(formValue);

      this.snackBarService.openSnackbar(
        this.snackMessage,
        this.snackNavigation,
        this.snackTime
      );
    } catch (error) {
      console.log(error);
    }
  }
}
