import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';

// interfaces
import { Contact } from '../../../../common/models/interfaces/contact';

// scripts
import functions from '../../../shared/scripts/recaptchaResponse.js';

@Component({
  selector: 'app-contact-us-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})
export class ContactUsFormComponent implements OnInit {
  @Output()
  formSubmittion: EventEmitter<Contact> = new EventEmitter<Contact>();

  contactForm: FormGroup;

  // errors
  nameError: boolean;
  emailError: boolean;
  messageError: boolean;

  // async
  requesting = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createForm();

    if (functions.scriptReady()) {
      functions.loadCaptchas();
    }
  }

  onSubmit() {
    this.requesting = true;

    if (this.contactForm.valid && functions.recaptchaResponse()) {
      this.formSubmittion.emit(this.contactForm.value);
    } else {
      this.requesting = false;
      if (this.nameErrors) {
        this.nameError = true;
      }
      if (this.emailErrors) {
        this.emailError = true;
      }
      if (this.messageErrors) {
        this.messageError = true;
      }
    }
  }

  get nameErrors() {
    const control = this.contactForm.get('name') as FormControl;

    return control.hasError('required');
  }

  get emailErrors() {
    const control = this.contactForm.get('email') as FormControl;

    return control.hasError('required') || control.hasError('email');
  }

  get messageErrors() {
    const control = this.contactForm.get('message') as FormControl;

    return control.hasError('required');
  }

  createForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.minLength(50)]],
      date: new Date()
    });
  }
}
