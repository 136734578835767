import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// fontawesome
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  homeBackgroundUrl: string;
  homePresentationTitle: string;

  backgroundImages: string[];
  presentationTitles: string[];

  // icons
  faArrowDown = faArrowDown;
  faArrowRight = faArrowRight;

  constructor(private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(
      `Kuest: Software Development Company | Bespoke Software Development | Software Development Outsourcing to Eastern Europe`
    );

    this.fillBackgroundImages();
    this.fillPresentationTitles();

    const imgNumber = Math.round(
      Math.random() * (this.backgroundImages.length - 1)
    );
    const titleNumber = Math.round(
      Math.random() * (this.presentationTitles.length - 1)
    );

    this.homeBackgroundUrl = this.backgroundImages[imgNumber];
    this.homePresentationTitle = this.presentationTitles[titleNumber];
  }

  navigate(divId: string, block: string) {
    const el = document.getElementById(divId);

    const options: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: block as ScrollLogicalPosition
    };

    el.scrollIntoView(options);
  }

  fillBackgroundImages() {
    this.backgroundImages = [
      // tslint:disable-next-line:max-line-length
      'https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fhome-page-images%2Fhome-bg1.jpg?alt=media&token=231784fd-0d7c-4f68-8ad2-453713817898',
      // tslint:disable-next-line:max-line-length
      'https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fhome-page-images%2Fhome-bg2.jpg?alt=media&token=27936850-f891-42ed-9cb9-24ee99666a60',
      // tslint:disable-next-line:max-line-length
      'https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fhome-page-images%2Fhome-bg3.jpg?alt=media&token=20206124-2773-4005-ba40-fcc5286fb42e'
    ];
  }

  fillPresentationTitles() {
    this.presentationTitles = [
      'Creating Customised, High Quality, Value-Driven Software for Your Company!',
      'Specialising in software development and internet products',
      'Driving Customised, High Quality, Cost-Effective Solutions for Your Company',
      'Managing and optimising your SaaS solutions is our priority',
      'Helping early, mid and later stage startups to take you to the next level'
    ];
  }
}
