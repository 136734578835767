import { Pipe, PipeTransform } from '@angular/core';

// moment.js
import * as moment from 'moment';

@Pipe({
  name: 'appHumanizeDate'
})
export class HumanizeDatePipe implements PipeTransform {
  transform(date: any): any {
    return moment(date).fromNow();
  }
}
