import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appCalculateDate'
})
export class CalculateDatePipe implements PipeTransform {
  transform(date): any {
    if (date && date.seconds) {
      const currentDate = new Date();
      const postDate = new Date(date.seconds * 1000);

      const calculatedDate = currentDate.getTime() - postDate.getTime();
      const finalDate = Math.floor(calculatedDate / (1000 * 60 * 60 * 24));

      return finalDate <= 5 ? true : false;
    }

    return false;
  }
}
