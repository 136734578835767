import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';

// modules
import { LandingModule } from '../kuest/landing/landing.module';

// routing
import { RouterModule } from '@angular/router';
import { LandingRouting } from '../kuest/landing/routing/landing.routing';
import { AppRouting } from './routing/app.routing';

// firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

// containers
import { AppComponent } from './containers/app/app.component';

// components
import { NotFoundComponent } from './components/not-found/not-found.component';

// error handler
import { AppErrorHandler } from '../common/error-reporting/bugsnag/bugsnag-setup';

// environment
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [
    // containers
    AppComponent,
    // components
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // modules
    LandingModule,
    // routing
    RouterModule,
    LandingRouting,
    AppRouting,
    // firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
