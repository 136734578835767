import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// interfaces
import { JobPosition } from '../../../../common/models/interfaces/job-position';

@Component({
  selector: 'app-career-view',
  templateUrl: './career-view.component.html',
  styleUrls: ['./career-view.component.scss']
})
export class CareerViewComponent implements OnInit {
  @Input() jobPositions: JobPosition[];

  date;

  constructor(private router: Router) {}

  ngOnInit() {
    this.date = new Date().getTime();
  }

  navigateToJobPosition(careerId: string) {
    this.router.navigate([`/career/${careerId}`]);
  }
}
