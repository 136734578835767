import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-critogo',
  templateUrl: './critogo.component.html',
  styleUrls: ['./critogo.component.scss']
})
export class CritogoComponent implements OnInit {
  ngOnInit() {
    document.getElementById('web-presentation').scrollIntoView({
      behavior: 'smooth'
    });
  }
}
