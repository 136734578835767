import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// services
import { LandingService } from '../../../shared/services/landing/landing.service';
import { SnackBarService } from '../../../../common/services/snack-bar/snack-bar.service';
import { SeoService } from '../../../shared/services/seo/seo.service';

// interfaces
import { Contact } from '../../../../common/models/interfaces/contact';

// fontawesome
import {
  faEnvelope,
  faPhone,
  faMapMarkedAlt
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsBackground =
    // tslint:disable-next-line:max-line-length
    'https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fcontact-us-images%2Fcontact-us-bg.png?alt=media&token=3261d7a8-5e12-415c-a3d6-840b71e955ef';

  // snackbar
  snackMessage =
    'Form submittion saved successfully. Thanks for writing to us.';
  snackNavigation = '/';
  snackTime = 3000;

  // icons
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faMapMarkedAlt = faMapMarkedAlt;

  // seo
  seoTitle = 'Kuest: Software Development Company | Contact us';
  seoDescription = `Can't find answers to your problems? Don’t worry, our software development support team is here to help.`;
  // seoImage =
  seoSlug = 'contact-us';

  constructor(
    private seo: SeoService,
    private titleService: Title,
    private landingService: LandingService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.seoTitle);

    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });
  }

  async handleContactForm(formValue: Contact) {
    try {
      await this.landingService.saveContactForm(formValue);

      this.snackBarService.openSnackbar(
        this.snackMessage,
        this.snackNavigation,
        this.snackTime
      );
    } catch (error) {
      console.log(error);
    }
  }
}
