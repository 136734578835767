import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { Title } from '@angular/platform-browser';

// animations
import {
  trigger,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations';

// services
import { LandingService } from '../../../shared/services/landing/landing.service';
import { SeoService } from '../../../shared/services/seo/seo.service';

// classes
import { SnackBarService } from '../../../../common/services/snack-bar/snack-bar.service';

// interfaces
import { PriceEstimatorChoice } from '../../../../common/models/interfaces/price-estimator-choice';

// fontawesome
import {
  faDesktop,
  faMobileAlt,
  faTabletAlt,
  faRocket,
  faClone,
  faBriefcase,
  faGamepad,
  faEnvelope,
  faShareAlt,
  faCubes,
  faCheck,
  faTimes,
  faQuestion,
  faThLarge,
  faStar,
  faTag,
  faMoneyBill,
  faThumbsUp,
  faCreditCard
} from '@fortawesome/free-solid-svg-icons';
import {
  faAndroid,
  faApple,
  faWindows
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-price-estimator',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './price-estimator.component.html',
  styleUrls: ['./price-estimator.component.scss'],
  animations: [
    trigger('pulse', [
      transition('false => true', [
        animate(
          500,
          keyframes([
            style({ 'box-shadow': '0 0 0 0 rgba(33, 36, 40, 0.7)' }),
            style({ 'box-shadow': '0 0 0 25px rgba(33, 36, 40, 0)' })
          ])
        )
      ])
    ])
  ]
})
export class PriceEstimatorComponent implements OnInit {
  priceEstimatorForm: FormGroup;

  // selected choice/s
  selectedChoice = '';
  selectedChoices = [];
  // available choices for this step
  currentStep: PriceEstimatorChoice;

  // steps
  step = 0;
  maxSteps;

  // all possible choices
  allChoices: PriceEstimatorChoice[];

  // choices currently made
  choicesMade: Array<string | string[]> = [];

  // pulse effect controler
  animatePulse = false;

  // icons
  faDesktop = faDesktop;
  faMobileAlt = faMobileAlt;
  faTabletAlt = faTabletAlt;
  faRocket = faRocket;
  faClone = faClone;
  faBriefcase = faBriefcase;
  faGamepad = faGamepad;
  faEnvelope = faEnvelope;
  faShareAlt = faShareAlt;
  faCubes = faCubes;
  faCheck = faCheck;
  faTimes = faTimes;
  faQuestion = faQuestion;
  faThLarge = faThLarge;
  faStar = faStar;
  faTag = faTag;
  faMoneyBill = faMoneyBill;
  faThumbsUp = faThumbsUp;
  faCreditCard = faCreditCard;
  faAndroid = faAndroid;
  faApple = faApple;
  faWindows = faWindows;

  // snackbar
  snackMessage = `Form submittion saved successfully. We'll contact you in a short period of time.`;
  snackNavigation = '/';
  snackTime = 3000;

  // seo
  seoTitle =
    'Kuest: Software Development Company | Software Project Price Estimate';
  seoDescription = '';
  // seoImage =
  seoSlug = 'price-estimator';

  // async
  requesting = false;

  // errors
  emailError: boolean;

  constructor(
    private seo: SeoService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private landingService: LandingService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.seoTitle);

    this.createForm();

    // gets all choices available
    this.fillAllChoices();

    // gets first choice
    this.getChoices(this.step);

    // based on choices gets a max step
    this.getMaxSteps();

    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });
  }

  // saves current selection
  makeChoice(choice: string) {
    if (this.currentStep.multiple) {
      if (!this.checkChoiceInArrayOfChoices(choice)) {
        this.selectedChoices.push(choice);
      } else {
        this.selectedChoices = this.selectedChoices.filter(
          item => item !== choice
        );
      }
    } else {
      this.selectedChoice = choice;
    }
  }

  // procceds to next step
  nextStep() {
    // check whether step exceds maxStep and if something is selected
    if (
      this.step < this.maxSteps &&
      (this.selectedChoice.trim() !== '' || this.selectedChoices.length !== 0)
    ) {
      // saves selected choice in all choices array
      this.currentStep.multiple
        ? (this.choicesMade[this.step] = this.selectedChoices)
        : (this.choicesMade[this.step] = this.selectedChoice);

      this.step++;

      if (this.step < this.maxSteps) {
        // gets next step
        this.getChoices(this.step);

        // clears variables or gets them if user went back
        if (this.choicesMade[this.step]) {
          this.currentStep.multiple
            ? (this.selectedChoices = this.choicesMade[this.step] as string[])
            : (this.selectedChoice = this.choicesMade[this.step] as string);
        } else {
          this.selectedChoices = [];
          this.selectedChoice = '';
        }

        // scrolls to top of view for better user experience
        document.getElementById('price-estimator-stepper').scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    } else {
      this.animatePulse = true;

      setTimeout(() => {
        this.animatePulse = false;
      }, 600);
    }

    // if last step, save all choices that were made
    if (this.step === this.maxSteps) {
      this.saveFinalChoices();
      window.scrollTo(0, -50);
    }
  }

  backStep() {
    this.step--;

    // gets previous step
    this.getChoices(this.step);

    // clears variables
    this.currentStep.multiple
      ? (this.selectedChoices = this.choicesMade[this.step] as string[])
      : (this.selectedChoice = this.choicesMade[this.step] as string);

    // scrolls to top of view for better user experience
    document.getElementById('price-estimator-stepper').scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

  getChoices(index: number) {
    this.currentStep = this.allChoices[index];
  }

  saveFinalChoices() {
    this.priceEstimatorForm.patchValue({
      choices: {
        platform: this.choicesMade[0],
        screen: this.choicesMade[1],
        type: this.choicesMade[2],
        login: this.choicesMade[3],
        feeds: this.choicesMade[4],
        looks: this.choicesMade[5],
        services: this.choicesMade[6],
        income: this.choicesMade[7]
      }
    });
  }

  getMaxSteps() {
    this.maxSteps = this.allChoices.length;
  }

  checkChoiceInArrayOfChoices(choice: string): boolean {
    return this.selectedChoices.includes(choice);
  }

  async onSubmit() {
    try {
      this.requesting = true;

      if (this.priceEstimatorForm.valid) {
        await this.landingService.savePriceRequest(
          this.priceEstimatorForm.value
        );

        this.snackBarService.openSnackbar(
          this.snackMessage,
          this.snackNavigation,
          this.snackTime
        );
      } else {
        this.requesting = false;
        if (this.emailErrors) {
          this.emailError = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  createForm() {
    this.priceEstimatorForm = this.formBuilder.group({
      choices: this.formBuilder.group({
        platform: '',
        screen: '',
        type: '',
        login: '',
        feeds: '',
        looks: '',
        services: '',
        income: ''
      }),
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: '',
      notes: ''
    });
  }

  // getters
  get emailErrors() {
    const control = this.priceEstimatorForm.get('email') as FormControl;

    return control.hasError('required') || control.hasError('email');
  }

  fillAllChoices() {
    this.allChoices = [
      {
        title: 'On which platforms do you want your app to be developed?',
        description: `Web is more accessible and has ultimate reach for desktop users.
        Android is the most popular mobile platform, in fact the Google Play Store doubles in app downloads in comparison to the App Store.
        On the other side Apple users have higher engagement and actually spend more money, however.`,
        multiple: true,
        choices: ['Web', 'Android', 'iOS'],
        icons: [this.faDesktop, this.faAndroid, this.faApple]
      },
      {
        title: 'What screen will your app be compatible with?',
        description: `Will your user value the larger display that desktop, tablets offer or the ubiquity that smartphones provide?
        Perhaps they’d benefit from having access to your app on all screen options.`,
        multiple: true,
        choices: ['Browser', 'Windows', 'Mac', 'Phone', 'Tablet'],
        icons: [
          this.faDesktop,
          this.faWindows,
          this.faApple,
          this.faMobileAlt,
          this.faTabletAlt
        ]
      },
      {
        title: 'What type of app are you creating?',
        description: `You really like an app and their business model but you have different solution for the problem?
        Or is it a game? Is it intended to support or provide another dimension to your company? Or are you aiming to
        develop the idea you’ve been sitting on by venturing into a startup?`,
        multiple: false,
        choices: ['Startup', 'Clone app', 'Business app', 'Game'],
        icons: [this.faRocket, this.faClone, this.faBriefcase, this.faGamepad]
      },
      {
        title: 'Will your users have to login?',
        description: `Will your users be sharing any private information such as credit card details, private messages or personal profiles?
        If so you should secure this information by allowing them to login to a personal account`,
        multiple: true,
        choices: ['Email', 'Social sites', 'External service'],
        icons: [this.faEnvelope, this.faShareAlt, this.faCubes]
      },
      {
        title: 'Are you going to have a feed (like a social feed)?',
        description: `A personal profile will be publicly displayed to all users and can include a picture of themselves,
        a description, their age and even interests!`,
        multiple: false,
        choices: ['Yes', 'No', 'Not sure'],
        icons: [this.faCheck, this.faTimes, this.faQuestion]
      },
      {
        title: 'Have you dreamed how good should your app look?',
        description: `Design is a big factor on success of apps nowadays. But, your idea seems so rock solid on business mode,
        so a standard and working app would be design arguable enough. Or, you really want to have something exceptional?`,
        multiple: false,
        choices: ['Standard', 'Customized / Designed'],
        icons: [this.faThLarge, this.faStar]
      },
      {
        title: 'Will your app need to use maps / location services?',
        description: `Location services allows apps to access or determine its user's location. This feature will be required
        if your user's experience is location dependent.`,
        multiple: false,
        choices: ['Yes', 'No', 'Not sure'],
        icons: [this.faCheck, this.faTimes, this.faQuestion]
      },
      {
        title: `Oh, if it's not secret, how will your app make money?`,
        description: ``,
        multiple: false,
        choices: [
          'Free app',
          'Paid app',
          'Subscription',
          'In-App purchases',
          'Hmm, no clue right now.'
        ],
        icons: [
          this.faTag,
          this.faMoneyBill,
          this.faThumbsUp,
          this.faCreditCard,
          this.faQuestion
        ]
      }
    ];
  }
}
