import { Component, ChangeDetectionStrategy } from '@angular/core';

// fontawesome
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import {
  faLinkedinIn,
  faTwitter,
  faGithub,
  faFacebookF,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  // icons
  faPhone = faPhone;
  faMapMarkerAlt = faMapMarkerAlt;
  faLinkedinIn = faLinkedinIn;
  faGithub = faGithub;
  faTwitter = faTwitter;
  faFacebookF = faFacebookF;
  faInstagram = faInstagram;
  faEnvelope = faEnvelope;
}
