import { Component, Input, OnChanges } from '@angular/core';

// services
import { SeoService } from 'src/app/kuest/shared/services/seo/seo.service';

// interfaces
import { Blog } from '../../../../common/models/interfaces/blog';

// fontawesome
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-blog-detail-view',
  templateUrl: './blog-detail-view.component.html',
  styleUrls: ['./blog-detail-view.component.scss']
})
export class BlogDetailViewComponent implements OnChanges {
  @Input() blog: Blog;

  // icons
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;

  constructor(private seoService: SeoService) {}

  ngOnChanges() {
    if (this.blog) {
      this.seoService.generateTags({
        title: this.blog.title,
        description: 'Blog@Kuest',
        image: this.blog.image,
        slug: `blog/${this.blog.id}`
      });
    }
  }

  openSharingDialog(social: string) {
    let url;

    if (social === 'twitter') {
      url = `https://twitter.com/intent/tweet?url=${window.location}`;
    }

    if (social === 'facebook') {
      url = `https://www.facebook.com/sharer/sharer.php?u=${window.location}`;
    }

    window.open(
      url,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,width=640,height=480'
    );
  }
}
