import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';

// interfaces
import { StartupApplication } from '../../../../common/models/interfaces/startup-application';

// scripts
import functions from '../../../shared/scripts/recaptchaResponse.js';

@Component({
  selector: 'app-start-ups-apply-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './start-ups-apply-form.component.html',
  styleUrls: ['./start-ups-apply-form.component.scss']
})
export class StartUpsApplyFormComponent implements OnInit {
  // errors
  nameError: boolean;
  emailError: boolean;
  startupNameError: boolean;
  startupIdeaError: boolean;
  errorMessage = '';

  @Output()
  formSubmittion: EventEmitter<StartupApplication> = new EventEmitter<
    StartupApplication
  >();

  applyForStartupForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createForm();

    if (functions.scriptReady()) {
      functions.loadCaptchas();
    }
  }

  onSubmit() {
    if (this.applyForStartupForm.valid && functions.recaptchaResponse()) {
      this.formSubmittion.emit(this.applyForStartupForm.value);
    } else {
      if (this.nameErrors) {
        this.nameError = true;
      }
      if (this.emailErrors) {
        this.emailError = true;
      }
      if (this.startupNameErrors) {
        this.startupNameError = true;
      }
      if (this.startupIdeaErrors) {
        this.startupIdeaError = true;
      }
    }
  }

  handleWords(event) {
    if (event.target.value.length > 500) {
      this.startupIdeaError = false;
    } else {
      this.startupIdeaError = true;
      this.errorMessage =
        'Your description should contain more than 500 characters';
    }
  }

  get nameErrors() {
    const control = this.applyForStartupForm.get('name') as FormControl;

    return control.hasError('required') || control.hasError('min');
  }

  get emailErrors() {
    const control = this.applyForStartupForm.get('email') as FormControl;

    return control.hasError('required') || control.hasError('email');
  }

  get startupNameErrors() {
    const control = this.applyForStartupForm.get('startupName') as FormControl;

    return control.hasError('required');
  }

  get startupIdeaErrors() {
    const control = this.applyForStartupForm.get('startupIdea') as FormControl;

    return control.hasError('required') || control.hasError('min');
  }

  createForm() {
    this.applyForStartupForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.email]],
      startupName: ['', Validators.required],
      webUrl: '',
      appStoreUrl: '',
      playStoreUrl: '',
      startupIdea: ['', [Validators.required, Validators.minLength(500)]]
    });
  }
}
