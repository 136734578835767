import { Injectable, ErrorHandler } from '@angular/core';

// bugsnag sript
import bugsnag from 'bugsnag-js';

// environment
import { environment } from '../../../../environments/environment';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  bugsnagClient: any;

  constructor() {
    this.bugsnagClient = bugsnag({
      apiKey: environment.bugsnagKey,
      notifyReleaseStages: ['production']
    });
  }

  public handleError(error: any) {
    const handledState = {
      severity: 'error',
      severityReason: { type: 'unhandledException' },
      unhandled: true
    };

    const report = new this.bugsnagClient.BugsnagReport(
      error.name,
      error.message,
      this.bugsnagClient.BugsnagReport.getStacktrace(error),
      handledState
    );

    if (error.ngDebugContext) {
      report.updateMetaData('angular', {
        component: error.ngDebugContext.component,
        context: error.ngDebugContext.context
      });
    }

    this.bugsnagClient.notify(report);
  }
}
