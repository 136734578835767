import { Injectable } from '@angular/core';

// modules
import { SharedModule } from '../../shared.module';

// meta
import { Meta } from '@angular/platform-browser';

// interfaces
import { SeoConfig } from '../../../../common/models/interfaces/seo-config';

@Injectable({
  providedIn: SharedModule
})
export class SeoService {
  constructor(private meta: Meta) {}

  generateTags(config: SeoConfig) {
    const defaultConfig: SeoConfig = {
      title:
        'Kuest: Software Development Company | Bespoke Software Development | Software Development Outsourcing to Eastern Europe',
      description:
        // tslint:disable-next-line:max-line-length
        'Kuest is a leading software development company in Eastern Europe & UK, offering custom software development applications, using the latest software development methodologies to help your business grow.',
      image:
        // tslint:disable-next-line:max-line-length
        'https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Fkuest-typelogo-black.svg?alt=media&token=ba12856b-caf6-4a73-a01a-66511a59379d',
      slug: '',
      ...config
    };

    this.meta.updateTag({
      name: 'twitter:title',
      content: defaultConfig.title
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: defaultConfig.description
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: defaultConfig.image
    });

    this.meta.updateTag({ property: 'og:title', content: defaultConfig.title });
    this.meta.updateTag({
      property: 'og:description',
      content: config.description
    });
    this.meta.updateTag({ property: 'og:image', content: defaultConfig.image });
    this.meta.updateTag({
      property: 'og:url',
      content: `https://kuest.co/${defaultConfig.slug}`
    });
  }
}
