import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// components
import { NotFoundComponent } from '../components/not-found/not-found.component';

const appRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: '../../admin/admin/admin.module#AdminModule'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRouting {}
