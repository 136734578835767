import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// rxjs
import { BehaviorSubject } from 'rxjs';

// services
import { LandingService } from '../../../shared/services/landing/landing.service';
import { SeoService } from '../../../../kuest/shared/services/seo/seo.service';

// interfaces
import { JobPosition } from '../../../../common/models/interfaces/job-position';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  jobPositions: BehaviorSubject<JobPosition[]> = new BehaviorSubject<
    JobPosition[]
  >(null);

  // seo
  seoTitle = 'Kuest: Software Development Company | Career';
  seoDescription = `At Kuest,
  we aim to define new approaches to software development and every Kuester plays an important role in our vision.
  Large software development projects do not always work out as planned – they take longer than expected and fail to deliver on promise.
  Companies like yours deserve more; they deserve to succeed.  They deserve to achieve the outcome you desire,
  through a creative and open approach to delivering business solutions.
  We are on track to create the best in software development space. Would you join us?`;
  // seoImage =
  seoSlug = 'career';

  constructor(
    private landingService: LandingService,
    private seo: SeoService,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle(this.seoTitle);

    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });

    try {
      this.jobPositions = await this.landingService.getAllJobPositions();
    } catch (error) {
      console.log('Error while getting job positions', error);
    }
  }
}
