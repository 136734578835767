import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// interfaces
import { Blog } from '../../../../common/models/interfaces/blog';

@Component({
  selector: 'app-blog-view',
  templateUrl: './blog-view.component.html',
  styleUrls: ['./blog-view.component.scss']
})
export class BlogViewComponent {
  @Input() blogs: Blog[];

  constructor(private router: Router) {}

  navigateToBlog(blogId: string) {
    this.router.navigate([`/blog/${blogId}`]);
  }
}
