import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'app-silverpost-android',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './silverpost-android.component.html',
  styleUrls: ['./silverpost-android.component.scss']
})
export class SilverpostAndroidComponent implements OnInit {
  ngOnInit() {
    document.getElementById('app-presentation').scrollIntoView({
      behavior: 'smooth'
    });
  }
}
