import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// rxjs
import { BehaviorSubject } from 'rxjs';

// services
import { LandingService } from '../../../shared/services/landing/landing.service';
import { SeoService } from '../../../shared/services/seo/seo.service';

// interfaces
import { News } from '../../../../common/models/interfaces/news';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  news: BehaviorSubject<News[]> = new BehaviorSubject<News[]>(null);

  // seo
  seoTitle = 'Kuest: Software Development Company | News';
  seoDescription = '';
  // seoImage =
  seoSlug = 'news';

  constructor(
    private landingService: LandingService,
    private seo: SeoService,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle(this.seoTitle);

    try {
      this.news = await this.landingService.getAllNews();
    } catch (error) {
      console.log('Error while getting news', error);
    }
    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      slug: this.seoSlug
    });
  }
}
