import { Injectable } from '@angular/core';

// rxjs
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

// modules
import { SharedModule } from '../../shared.module';

// firebase
import { AngularFirestore } from '@angular/fire/firestore';

// interfaces
import { Contact } from '../../../../common/models/interfaces/contact';
import { StartupApplication } from '../../../../common/models/interfaces/startup-application';
import { PriceRequest } from '../../../../common/models/interfaces/price-request';

// interfaces
import { Blog } from '../../../../common/models/interfaces/blog';
import { News } from '../../../../common/models/interfaces/news';
import { JobPosition } from '../../../../common/models/interfaces/job-position';

// constants
import * as CONSTANTS from '../../../../common/models/constants/constants';

@Injectable({
  providedIn: SharedModule
})
export class LandingService {
  constructor(private afs: AngularFirestore) { }

  async saveContactForm(contact: Contact) {
    return this.afs.collection(CONSTANTS.CONTACTS_COLLECTION_NAME).add(contact);
  }

  async saveStartup(startup: StartupApplication) {
    return this.afs.collection(CONSTANTS.STARTUPS_COLLECTION_NAME).add(startup);
  }

  async savePriceRequest(priceRequest: PriceRequest) {
    return this.afs
      .collection(CONSTANTS.PRICE_REQUEST_COLLECTION_NAME)
      .add(priceRequest);
  }

  async getAllBlogs() {
    return this.afs
      .collection(CONSTANTS.BLOG_COLLECTION_NAME, ref =>
        ref.orderBy('createdDate', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...(data as object) };
          })
        )
      ) as BehaviorSubject<Blog[]>;
  }

  async getBlogById(blogId: string) {
    return this.afs
      .collection(CONSTANTS.BLOG_COLLECTION_NAME)
      .doc(blogId)
      .snapshotChanges()
      .pipe(
        map(actions => {
          const data = actions.payload.data();
          const id = actions.payload.id;
          return { id, ...(data as object) };
        })
      ) as BehaviorSubject<Blog>;
  }

  async getAllNews() {
    return this.afs
      .collection(CONSTANTS.NEWS_COLLECTION_NAME, ref =>
        ref.orderBy('createdDate', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...(data as object) };
          })
        )
      ) as BehaviorSubject<News[]>;
  }

  async getNewsById(newsId: string) {
    return this.afs
      .collection(CONSTANTS.NEWS_COLLECTION_NAME)
      .doc(newsId)
      .snapshotChanges()
      .pipe(
        map(actions => {
          const data = actions.payload.data();
          const id = actions.payload.id;
          return { id, ...(data as object) };
        })
      ) as BehaviorSubject<News>;
  }

  // career
  async getAllJobPositions() {
    return this.afs
      .collection(CONSTANTS.CAREER_COLLECTION_NAME, ref =>
        ref.orderBy('createdDate', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as JobPosition;
            const id = a.payload.doc.id;
            return { id, ...(data as object) };
          })
        )
      ) as BehaviorSubject<JobPosition[]>;
  }

  async getJobPositionById(jobPositionId: string) {
    return this.afs
      .collection(CONSTANTS.CAREER_COLLECTION_NAME)
      .doc(jobPositionId)
      .snapshotChanges()
      .pipe(
        map(actions => {
          const data = actions.payload.data();
          const id = actions.payload.id;
          return { id, ...(data as object) };
        })
      ) as BehaviorSubject<JobPosition>;
  }
}
