import './recaptcha.js';

const functions = {
  recaptchaResponse() {
    return state;
  },

  scriptReady() {
    return ready;
  },

  loadCaptchas() {
    onLoadFunction();
  }
};

export default functions;
