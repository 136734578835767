import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  Renderer2
} from '@angular/core';

import { headerAnimations } from './header.animations';

@Component({
  selector: 'app-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: headerAnimations
})
export class HeaderComponent {
  hamburgerMenu = false;

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.hamburgerMenu) {
      this.toggleHamburgerMenu();
    }
  }

  @HostListener('window:resize', ['$event'])
  onresize() {
    if (this.hamburgerMenu) {
      this.toggleHamburgerMenu();
    }
  }

  constructor(private renderer: Renderer2) {}

  toggleHamburgerMenu() {
    this.hamburgerMenu = !this.hamburgerMenu;

    if (this.hamburgerMenu) {
      this.renderer.addClass(document.getElementById('hamburger'), 'change');
    } else {
      this.renderer.removeClass(document.getElementById('hamburger'), 'change');
    }
  }
}
