import { Component, Input, OnChanges } from '@angular/core';

// services
import { SeoService } from 'src/app/kuest/shared/services/seo/seo.service';

// interfaces
import { News } from '../../../../common/models/interfaces/news';

// fontawesome
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-news-detail-view',
  templateUrl: './news-detail-view.component.html',
  styleUrls: ['./news-detail-view.component.scss']
})
export class NewsDetailViewComponent implements OnChanges {
  @Input() news: News;

  // icons
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;

  constructor(private seoService: SeoService) {}

  ngOnChanges() {
    if (this.news) {
      this.seoService.generateTags({
        title: this.news.title,
        description: 'News@Kuest',
        slug: `news/${this.news.id}`
      });
    }
  }

  openSharingDialog(social: string) {
    let url;

    if (social === 'twitter') {
      url = `https://twitter.com/intent/tweet?url=${window.location}`;
    }

    if (social === 'facebook') {
      url = `https://www.facebook.com/sharer/sharer.php?u=${window.location}`;
    }

    window.open(
      url,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,width=640,height=480'
    );
  }
}
