import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

// shared modules
import { SharedModule } from '../shared/shared.module';

// modules
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// containers
import { ContactUsComponent } from './containers/contact-us/contact-us.component';
import { ProductsComponent } from './containers/products/products.component';
import { StartUpsComponent } from './containers/start-ups/start-ups.component';
import { StartUpsApplyComponent } from './containers/start-ups-apply/start-ups-apply.component';
import { NewsComponent } from './containers/news/news.component';
import { NewsDetailComponent } from './containers/news-detail/news-detail.component';
import { BlogComponent } from './containers/blog/blog.component';
import { BlogDetailComponent } from './containers/blog-detail/blog-detail.component';

// components
import { ServicesComponent } from './components/services/services.component';
import { StartUpsViewComponent } from './components/start-ups-view/start-ups-view.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { PriceEstimatorComponent } from './components/price-estimator/price-estimator.component';
import { ContactUsFormComponent } from './components/contact-us-form/contact-us-form.component';
import { FaqComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { SilverpostIosComponent } from './components/products/silverpost/silverpost-ios/silverpost-ios.component';
import { SilverpostAndroidComponent } from './components/products/silverpost/silverpost-android/silverpost-android.component';
import { SilverpostWebComponent } from './components/products/silverpost/silverpost-web/silverpost-web.component';
import { BlogViewComponent } from './components/blog-view/blog-view.component';
import { BlogDetailViewComponent } from './components/blog-detail-view/blog-detail-view.component';
import { StartUpsApplyFormComponent } from './components/start-ups-apply-form/start-ups-apply-form.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PressKitComponent } from './components/press-kit/press-kit.component';
import { NewsDetailViewComponent } from './components/news-detail-view/news-detail-view.component';
import { NewsViewComponent } from './components/news-view/news-view.component';
import { CareerComponent } from './containers/career/career.component';
import { CareerDetailComponent } from './containers/career-detail/career-detail.component';
import { CareerDetailViewComponent } from './components/career-detail-view/career-detail-view.component';
import { CareerViewComponent } from './components/career-view/career-view.component';
import { CritogoComponent } from './components/products/critogo/critogo.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  declarations: [
    // containers
    BlogComponent,
    ContactUsComponent,
    ProductsComponent,
    StartUpsComponent,
    StartUpsApplyComponent,
    NewsComponent,
    NewsDetailComponent,
    BlogDetailComponent,
    // components
    ServicesComponent,
    AboutUsComponent,
    StartUpsViewComponent,
    PriceEstimatorComponent,
    ContactUsFormComponent,
    FaqComponent,
    HomeComponent,
    SilverpostIosComponent,
    SilverpostAndroidComponent,
    SilverpostWebComponent,
    PrivacyPolicyComponent,
    StartUpsApplyFormComponent,
    PressKitComponent,
    NewsDetailViewComponent,
    NewsViewComponent,
    BlogViewComponent,
    BlogDetailViewComponent,
    CareerComponent,
    CareerDetailComponent,
    CareerDetailViewComponent,
    CareerViewComponent,
    CritogoComponent
  ]
})
export class LandingModule {}
