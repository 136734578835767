import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// containers
import { ContactUsComponent } from '../containers/contact-us/contact-us.component';
import { ProductsComponent } from '../containers/products/products.component';
import { BlogComponent } from '../containers/blog/blog.component';
import { BlogDetailComponent } from '../containers/blog-detail/blog-detail.component';
import { StartUpsComponent } from '../containers/start-ups/start-ups.component';
import { StartUpsApplyComponent } from '../containers/start-ups-apply/start-ups-apply.component';
import { NewsComponent } from '../containers/news/news.component';
import { NewsDetailComponent } from '../containers/news-detail/news-detail.component';

// components
import { ServicesComponent } from '../components/services/services.component';
import { AboutUsComponent } from '../components/about-us/about-us.component';
import { StartUpsViewComponent } from '../components/start-ups-view/start-ups-view.component';
import { PriceEstimatorComponent } from '../components/price-estimator/price-estimator.component';
import { FaqComponent } from '../components/faq/faq.component';
import { HomeComponent } from '../components/home/home.component';
import { SilverpostIosComponent } from '../components/products/silverpost/silverpost-ios/silverpost-ios.component';
import { SilverpostAndroidComponent } from '../components/products/silverpost/silverpost-android/silverpost-android.component';
import { SilverpostWebComponent } from '../components/products/silverpost/silverpost-web/silverpost-web.component';
import { PrivacyPolicyComponent } from '../components/privacy-policy/privacy-policy.component';
import { PressKitComponent } from '../components/press-kit/press-kit.component';
import { CareerComponent } from '../containers/career/career.component';
import { CareerDetailComponent } from '../containers/career-detail/career-detail.component';
import { CritogoComponent } from '../components/products/critogo/critogo.component';

// routes
const landingRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { animations: 'HomePage' }
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'blog/:blogId',
    component: BlogDetailComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'price-estimator',
    component: PriceEstimatorComponent
  },
  {
    path: 'products',
    component: ProductsComponent,
    children: [
      {
        path: 'silverpost-ios',
        component: SilverpostIosComponent
      },
      {
        path: 'silverpost-android',
        component: SilverpostAndroidComponent
      },
      {
        path: 'silverpost-web',
        component: SilverpostWebComponent
      },
      {
        path: 'critogo-web',
        component: CritogoComponent
      }
    ]
  },
  {
    path: 'services',
    component: ServicesComponent
  },
  {
    path: 'startups',
    component: StartUpsComponent,
    children: [
      {
        path: '',
        component: StartUpsViewComponent
      },
      {
        path: 'apply',
        component: StartUpsApplyComponent
      }
    ]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'press-kit',
    component: PressKitComponent
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'news/:newsId',
    component: NewsDetailComponent
  },
  {
    path: 'career',
    component: CareerComponent
  },
  {
    path: 'career/:careerId',
    component: CareerDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(landingRoutes)],
  exports: [RouterModule]
})
export class LandingRouting {}
