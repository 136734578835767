import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'app-silverpost-web',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './silverpost-web.component.html',
  styleUrls: ['./silverpost-web.component.scss']
})
export class SilverpostWebComponent implements OnInit {
  ngOnInit() {
    document.getElementById('web-presentation').scrollIntoView({
      behavior: 'smooth'
    });
  }
}
