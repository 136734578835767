import { Component, Input } from '@angular/core';
import { JobPosition } from '../../../../common/models/interfaces/job-position';

@Component({
  selector: 'app-career-detail-view',
  templateUrl: './career-detail-view.component.html',
  styleUrls: ['./career-detail-view.component.scss']
})
export class CareerDetailViewComponent {
  @Input() jobPosition: JobPosition;
}
