import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

// rxjs
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';

// services
import { LandingService } from '../../../shared/services/landing/landing.service';

// interfaces
import { News } from '../../../../common/models/interfaces/news';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit, OnDestroy {
  news: BehaviorSubject<News>;

  subscription = true;

  constructor(
    private landingService: LandingService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.route.params
      .pipe(
        switchMap(async (param: Params) => {
          return this.landingService.getNewsById(param['newsId']);
        }),
        takeWhile(() => this.subscription)
      )
      .subscribe(news => {
        this.news = news;
      });
  }

  ngOnDestroy() {
    this.subscription = false;
  }
}
