export const environment = {
  production: false,

  // firebase
  firebase: {
    apiKey: 'AIzaSyCmMaxY2VCge7ILf7_EhVVMISgEz4Q2CsU',
    authDomain: 'kuest-website-dev.firebaseapp.com',
    databaseURL: 'https://kuest-website-dev.firebaseio.com',
    projectId: 'kuest-website-dev',
    storageBucket: 'kuest-website-dev.appspot.com',
    messagingSenderId: '286144612993'
  },

  // bug snag
  bugsnagKey: '18f05c4fd2f6fa6758115d8b362593bb'
};
