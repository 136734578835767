import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

// services
import { SeoService } from "../../../shared/services/seo/seo.service";

// interfaces
import { TeamMember } from "../../../../common/models/interfaces/team-member";
import { CultureItem } from "../../../../common/models/interfaces/culture-item";

// fontawesome
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedinIn,
  faTwitter,
  faGithub,
  faDribbble,
  faBehance
} from "@fortawesome/free-brands-svg-icons";

@Component({
  selector: "app-about-us",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"]
})
export class AboutUsComponent implements OnInit {
  items: CultureItem[];
  mySelectedItem;
  carouselIndex = 0;

  selectedMember: TeamMember;
  noProfileImage = "no-profile-picture";

  // icons
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faLinkedinIn = faLinkedinIn;
  faTwitter = faTwitter;
  faGithub = faGithub;
  faDribbble = faDribbble;
  faBehance = faBehance;

  // seo
  seoTitle = "Kuest: Software Development Company | About us";
  seoDescription = "Kuest about us page";
  // seoImage =
  seoSlug = "about-us";

  constructor(private seo: SeoService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(this.seoTitle);

    this.fillItems();

    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });

    // my
    this.selectItem(0);
  }

  scrollIntoView(divId: string) {
    document.getElementById(divId).scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }

  nextItem() {
    if (this.carouselIndex < this.items.length - 1) {
      this.carouselIndex++;

      this.selectItem(this.carouselIndex);
    }
  }

  prevItem() {
    if (this.carouselIndex > 0) {
      this.carouselIndex--;

      this.selectItem(this.carouselIndex);
    }
  }

  selectItem(index: number) {
    this.mySelectedItem = this.items[index];
  }

  fillItems() {
    this.items = [
      {
        img:
          // tslint:disable-next-line:max-line-length
          "https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fabout-us-images%2Fhardwork.jpg?alt=media&token=13fea22d-e2b7-400a-9ac5-c37b81f90000",
        title: "Hardwork",
        description: `We are, first, our shared heritage.  We are Eastern European, and working hard is part of our
        DNA. We’re committed to wowing you with the quality of our work, and hearing you say the experience was far
        better than you ever thought it would be. We will not stop until we see your excitement from the finished product.
        `
      },
      {
        img:
          // tslint:disable-next-line:max-line-length
          "https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fabout-us-images%2Fdiversity.jpg?alt=media&token=e9e570d0-03b5-43a0-afff-6fe93717641a",
        title: "Diversity",
        description: `At Kuest, everyone is different, and everyone is the same – we truly embrace diversity.
        Our strength as a company is rooted in the character and uniqueness of our team. We value and respect
        individual differences. We believe the essence of diversity makes each and every  product stronger,
        full of the wealth of ideas, backgrounds, and perspectives, and harnessed to create business value.`
      },
      {
        img:
          // tslint:disable-next-line:max-line-length
          "https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fabout-us-images%2Ftransparency.jpg?alt=media&token=bead5584-01c4-4e33-8c45-4dfd7e86293c",
        title: "Transparency",
        description: `What you see is what you get.  There is no closed-door policy, no "I'm too busy for you"
        attitude.  Your needs are our number one priority. Transparency is about being open, not closed minded
        and making decisions by consensus.  We are excited about sharing our ideas with you, and moving forward
        to share something truly amazing.`
      },
      {
        img:
          // tslint:disable-next-line:max-line-length
          "https://firebasestorage.googleapis.com/v0/b/kuest-website-dev.appspot.com/o/project-images%2Flanding-images%2Fabout-us-images%2Fmeritocracy.jpg?alt=media&token=de88889d-93ee-4b54-9e0f-413dd934ec96",
        title: "Meritocracy",
        description: `We attract the best and the brightest because we foster meritocracy.
        It’s a guiding principle where we hire, reward and promote the best people based on their merits and accomplishments.
        We pride ourselves on having a unique culture of meritocracy.  You’ll notice the creative energy immediately.
        That comes from years of experience and knowing what works.
        `
      }
    ];
  }
}
