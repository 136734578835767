import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// services
import { SeoService } from '../../../shared/services/seo/seo.service';

@Component({
  selector: 'app-start-ups',
  templateUrl: './start-ups.component.html',
  styleUrls: ['./start-ups.component.scss']
})
export class StartUpsComponent implements OnInit {
  // seo
  seoTitle = 'Kuest: Software Development Company | Startups';
  seoDescription = '';
  // seoImage =
  seoSlug = 'startups';

  constructor(private seo: SeoService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(this.seoTitle);

    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });
  }
}
