import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

// rxjs
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';

// services
import { LandingService } from '../../../shared/services/landing/landing.service';

// interfaces
import { Blog } from '../../../../common/models/interfaces/blog';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit, OnDestroy {
  blog: BehaviorSubject<Blog>;

  subscription = true;

  constructor(
    private landingService: LandingService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.route.params
      .pipe(
        switchMap(async (param: Params) => {
          return this.landingService.getBlogById(param['blogId']);
        }),
        takeWhile(() => this.subscription)
      )
      .subscribe(blog => {
        this.blog = blog;
      });
  }

  ngOnDestroy() {
    this.subscription = false;
  }
}
