import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// material
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar, private router: Router) { }

  openSnackbar(message: string, navigatePath: string, time: number) {
    this.snackBar
      .open(message, '', {
        duration: time
      })
      .afterDismissed()
      .subscribe(status => {
        if (status) {
          this.router.navigate([navigatePath]);
        }
      });
  }

  openSimpleSnackbar(message: string) {
    this.snackBar.open(message, '');
  }

  openSnackbarWithTimer(message: string, time: number) {
    this.snackBar.open(message, '', {
      duration: time
    });
  }

  closeSnackbar() {
    this.snackBar.dismiss();
  }
}
