import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// rxjs
import { BehaviorSubject } from 'rxjs';

// services
import { LandingService } from '../../../shared/services/landing/landing.service';
import { SeoService } from '../../../shared/services/seo/seo.service';

// interfaces
import { Blog } from '../../../../common/models/interfaces/blog';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogs: BehaviorSubject<Blog[]> = new BehaviorSubject<Blog[]>(null);

  // seo
  seoTitle = 'Kuest: Software Development Company | Blog';
  seoDescription = '';
  // seoImage =
  seoSlug = 'blog';

  constructor(
    private landingService: LandingService,
    private seo: SeoService,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle(this.seoTitle);

    try {
      this.blogs = await this.landingService.getAllBlogs();
    } catch (error) {
      console.log('Error while getting blogs', error);
    }
    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });
  }
}
