import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

// services
import { SeoService } from '../../../shared/services/seo/seo.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  productsBackgroundImage: string;

  // seo
  seoTitle = 'Kuest: Software Development Company | Products';
  seoDescription = '';
  // seoImage =
  seoSlug = 'products';

  constructor(
    private seo: SeoService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.seoTitle);
    this.productsBackgroundImage = `url('../../../../assets/images/landing/home/presentation-section/home-bg3.jpg')`;

    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });
  }

  navigateToChild(link: string) {
    this.router.navigate([link], { relativeTo: this.route });
  }
}
