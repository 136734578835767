import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// services
import { SeoService } from '../../../shared/services/seo/seo.service';

@Component({
  selector: 'app-press-kit',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './press-kit.component.html',
  styleUrls: ['./press-kit.component.scss']
})
export class PressKitComponent implements OnInit {
  // seo
  seoTitle = 'Kuest: Software Development Company | Press Kit';
  seoDescription = '';
  // seoImage =
  seoSlug = 'press-kit';

  constructor(private seo: SeoService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(this.seoTitle);

    this.seo.generateTags({
      title: this.seoTitle,
      description: this.seoDescription,
      // image: this.seoImage,
      slug: this.seoSlug
    });
  }
}
