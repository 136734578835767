import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// material
import { MaterialModule } from '../../material/material.module';

// modules
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// components
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CalculateDatePipe } from './pipes/calculate-date.pipe';
import { HumanizeDatePipe } from './pipes/humanize-date.pipe';

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule, FontAwesomeModule],
  declarations: [
    HeaderComponent,
    FooterComponent,
    CalculateDatePipe,
    HumanizeDatePipe
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CalculateDatePipe,
    HumanizeDatePipe
  ]
})
export class SharedModule {}
