var state;
var ready;

function recaptchaCallback(res) {
  if (res) {
    state = true;
    return;
  }
  state = false;
}

const options = {
  sitekey: '6LdminUUAAAAAOteLHMYCnKgKo83H3Lgae-oEHMe',
  callback: recaptchaCallback
};

function onLoadFunction(res) {
  ready = true;

  if (document.getElementById('contact-captcha')) {
    contactCaptcha = grecaptcha.render('contact-captcha', options);
  }

  if (document.getElementById('apply-for-startup-captcha')) {
    authCaptcha = grecaptcha.render('apply-for-startup-captcha', options);
  }
}
